<template>
  <BaseModal name="management-modal" id="management-modal" idModal="management-modal" size="md" @shown="openModal"
    title="Relatório">
    <div>
      <div>
        <label for="report-name">Nome do relatório</label>
        <input class="w-100" v-model="title" type="text" id="report-name" placeholder="Digite o nome do relatório">
      </div>

      <div>
        <label for="report-name">Período padrão</label>
        <multiselect
          v-model="period"
          placeholder="Selecione o período"
          label="name"
          track-by="value"
          :options="periods"
          :multiple="false"
          :taggable="true"
          class=""
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          :allow-empty="false"
          >
        </multiselect>

        <p>O período padrão permite escolher entre os últimos 7, 14 e 30 dias para obter métricas dinâmicas a partir da data atual.</p>
      </div>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton @click="$bvModal.hide('management-modal')" variant="link-info" class="mr-2">
        Cancelar
      </BaseButton>
      <BaseButton @click="saveManagement()" variant="primary" class="mr-2">
        Salvar
      </BaseButton>

    </template>
  </BaseModal>
</template>
<script>
//Components
import Multiselect from "vue-multiselect";

//Services
import TagService from "@/services/resources/TagService";
const serviceTag = TagService.build();

export default {
  components: {
    Multiselect
  },
  props: {
    forms: {
      type: Array,
      default: () => {
        return [];
      }
    },
    tags: {
      type: Array,
      required: true
    },
    sellers: {
      type: Array,
      default: () => {
        return [0];
      }
    },
    management: {
      type: Object,
      default: () => {
        return {};
      }
    },
    typeGraph: {
      type: String,
      default: "pizza"
    }
  },
  data() {
    return {
      period: {
        value: 7,
        name: "7 dias",
      },
      periods: [
        { value: 7, name: "Últimos 7 dias" },
        { value: 14, name: "Últimos 14 dias" },
        { value: 30, name: "Últimos 30 dias" }
      ],
      title: ""
    };
  },
  computed: {},
  methods: {
    openModal() {
      if(this.management.id) {
        this.title = this.management.title;
        this.period = this.periods.find(period => period.value == this.management.period);
        return;
      }

      this.resetFields();
    },
    customAlert(content, variant = "success"){
        this.$grToast.toast(content, {
          title: "Relatório de tags",
          variant: variant,
          autoHideDelay: 5000,
          appendToast: true,
        });
    },
    resetFields() {
      this.title = "";

      this.period = {
        value: 7,
        name: "7 dias",
      };
    },
    saveManagement() {
      if(!this.title){
        this.customAlert("Por favor, insira o nome do relatório", "danger");
        return;
      }

      if(this.title.length > 20){
        this.customAlert("Por favor, insira um nome de no máximo 20 caracteres", "danger");
        return;
      }

      const managementId = this.management.id ?? 0;
      const data = {
        id: `/management/${managementId ? managementId : 'save'}`,
        title: this.title,
        tags: this.tags.map(tag => tag.id),
        forms: this.forms.map((form) => form.id),
        sellers: this.sellers.map((seller) => seller.id),
        period: this.period.value,
        typeGraph: this.typeGraph
      };

      if(managementId){
        serviceTag
        .update(data)
        .then((response) => {
          this.customAlert("Relatório salvo");
          this.resetFields();
          this.$emit('updateManagement', response.management);
          this.$bvModal.hide('management-modal');
        })
        .catch((err) => {
          if (err?.response?.data?.message === "this title already been taken") {
            this.customAlert("Já existe um relatório salvo com esse título", "danger");
            return;
          }
          this.customAlert("Erro ao salvar relatório", "danger");
        })
        return;
      }

      serviceTag
        .createId(data)
        .then((response) => {
          this.$router.push({path: `/${this.$route.params.dynamicRoute}/estrategico/relatorio-tag/${response.management.id}`});
          this.customAlert("Relatório salvo");
          this.resetFields();
          this.$emit('updateManagement', response.management);
          this.$bvModal.hide('management-modal');
        })
        .catch((err) => {
          if (err?.response?.data?.message === "this title already been taken") {
            this.customAlert("Já existe um relatório salvo com esse título", "danger");
            return;
          }
          this.customAlert("Erro ao salvar relatório", "danger");
        })
    }
  },
};
</script>

<style scoped lang="scss">
p {
  font-size: 16px;
  color: #81858e !important;
  font-weight: 500;
  margin-top: 10px;
}
</style>
