<template>
  <div>
    <Relatorio/>
  </div>
</template>

<script>
import Relatorio from "@/components/Tags/Relatorio/Relatorio.vue";

export default {
  components: {
    Relatorio
  },
}
</script>

